import { defineStore } from "pinia";
import { useSharedStore } from "@/entrypoints/stores/shared";
import crypto from "@/entrypoints/shared/aion_crypto";
import apiClient from "@/entrypoints/frontend/apiClient";
import { reactive, ref } from "vue";
import type { SignUpVoterParticipation } from "@/types";

export const useSignUpSessionStore = defineStore("signUpSession", () => {
  const sharedStore = useSharedStore();

  // State
  const error = ref<string>(null);
  const privateKey = ref<string>(null);
  const publicKey = ref<string>(null);
  const signature = ref<string>(null);
  const answered = ref<boolean>(null);
  const voter = reactive<SignUpVoterParticipation>({
    name: null,
    participating: null,
  });

  const authenticate = async (electionCodes: string[]) => {
    privateKey.value = electionCodes.map((code: string) =>
      crypto.electionCodeToPrivateKey(code, "pbkdf2")).reduce(crypto.addBigNums);
    publicKey.value = crypto.generateKeyPair(privateKey.value).public_key;
    signature.value = crypto.generateSchnorrSignature("", privateKey.value);

    try {
      const response = await apiClient.post(`${sharedStore.electionUrl}/authenticate_sign_up`, {
        public_key: publicKey.value,
        signature: signature.value,
      });

      const data = await response.data;

      if (data.voter) {
        voter.name = data.voter.name;
        voter.participating = data.voter.participating;
        answered.value = voter.participating !== null;
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.error);
      }
    }
  }

  const submitAnswer = async (participation: boolean) => {
    try {
      const response = await apiClient.put(`${sharedStore.electionUrl}/submit_sign_up`, {
        signature: signature.value,
        public_key: publicKey.value,
        participating: participation,
      });

      const data = await response.data;

      if (data.voter) {
        voter.participating = data.voter.participating;
        answered.value = true;
      }
    } catch (error) {
      destroySession();
      if (error.response) {
        setError(error.response.data.error);
      }
    }
  }

  const setAnswered = (payload: boolean) => {
    answered.value = payload;
  }

  const setError = (payload: string) => {
    error.value = payload;
  }

  const destroySession = () => {
    privateKey.value = null;
    publicKey.value = null;
    signature.value = null;
    answered.value = null;
    voter.name = null;
    voter.participating = null;
  }

  return {
    error,
    setError,
    answered,
    setAnswered,
    voter,
    publicKey,
    authenticate,
    submitAnswer,
    destroySession,
  }
});